<template>
  <v-footer
    id="pages-core-footer"
    color="transparent"
    :dark="$vuetify.theme.dark"
    style="
      bottom: 0;
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,1);
      z-index: 2;
      padding-bottom: 0px;
    "
  >
    <v-container style="">
      <v-row
        justify="center"
        align="center"
      >
        <div class="body-1 font-weight-light pt-md-0 text-center">
          &copy; {{new Date().getFullYear()}}, made with
          <v-icon size="20">
            mdi-heart
          </v-icon>
          by <a target="_blank" class="footer-link" href="https://www.dooh.com">DOOH.com</a>
        </div>
      </v-row>
      <v-row 
        justify="center"
        align="center"
        no-gutters
        style="
          margin-top: 5px;
        "
      >
        <!-- Link to Privacy Policy -->
        <v-btn
          class="text-uppercase"
          plain
          small
          href="/#/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </v-btn>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'PagesCoreFooter',

    data: () => ({
      links: [
        {
          href: 'https://www.dooh.com/',
          text: 'DOOH.com',
        },
      ],
    }),
  }
</script>

<style lang="scss">
  #pages-core-footer {
    a {
      color: #fff;
      font-size: .825rem;
      font-weight: 500;
      text-decoration: none;
      text-transform: uppercase;
    }
    .footer-link {
      color: var(--v-primary-base) !important;
    }
  }
</style>
